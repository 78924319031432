
@import "tailwindcss/base";

@import "css/global.scss";
@import "css/typography.scss";
@import "css/layout.scss";

@import "tailwindcss/components";
@import "tailwindcss/utilities";

$hamburger-layer-width         : 36px !default;
$hamburger-layer-height        : 2px !default;
$hamburger-layer-color         : #fff !default;
$hamburger-layer-border-radius : 0px !default;
@import "../node_modules/hamburgers/_sass/hamburgers/hamburgers.scss";

