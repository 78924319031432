body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1, .h1 {
    @apply text-2xl;
    @screen md {
        @apply text-4xl;
    }
}

h2, .h2 {
    @apply text-3xl;
}

h3, .h3 {
    @apply text-2xl;
}

h4, .h4 {
    @apply text-xl;
}

h1, h2, h3, h4, h5, h6, p, ul, ol, hr {
    @apply mb-one;
    &:last-child {
        @apply mb-0;
    }
}

a {
    @apply hover:underline;
}

button, .button {
    @apply inline-block bg-accent text-white px-one py-half;
}

ul {
    @apply list-none;
    li {
        @apply relative pl-one;
    }
    li::before {
        content: '\2022';
        @apply text-accent absolute left-0;
    }
}

ol {
    @apply list-none;
    counter-reset: ol;
    li {
        counter-increment: ol;
        @apply relative pl-one;
        &::before {
            content: counter(ol) ".";
            @apply font-bold absolute text-accent left-0;
        }
    }
}

ul, ol {
    @apply list-inside;
    li {
        @apply mb-quarter;
    }
}

hr {
    @apply border border-accent mx-auto;
    max-width: 4.25rem;
}

nav {
    ul, ol {
        li:before {
            display: none;
        }
    }
}

figure figcaption p:first-child span.text-md {
    @apply block;
    min-height: 3.22875rem;
}

::selection {
    @apply bg-base text-white;
}