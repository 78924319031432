// Import your CSS/SCSS files here:

body {
  
  
}

:focus {
    @apply outline-accent;
}

[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
    appearance: none;
    height: 10px;
    width: 10px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMjMuMDUiIGhlaWdodD0iMTIzLjA1IiB2aWV3Qm94PSIwIDAgMTIzLjA1IDEyMy4wNSI+PHBhdGggZD0iTTEyMS4zMiwxMC45M2wtOC41LTguNGE2LjEsNi4xLDAsMCwwLTguNSwwTDYxLjkyLDQ0LjkyLDE4LjczLDEuNzNhNi4wOCw2LjA4LDAsMCwwLTguNSwwbC04LjUsOC41YTYuMDgsNi4wOCwwLDAsMCwwLDguNWw0My4xLDQzLjEtNDIuMyw0Mi41YTYuMSw2LjEsMCwwLDAsMCw4LjVsOC41LDguNWE2LjEsNi4xLDAsMCwwLDguNSwwbDQyLjM5LTQyLjQsNDIuNCw0Mi40YTYuMSw2LjEsMCwwLDAsOC41LDBsOC41LTguNWE2LjEsNi4xLDAsMCwwLDAtOC41bC00Mi41LTQyLjQsNDIuNC00Mi40QTYuMTMsNi4xMywwLDAsMCwxMjEuMzIsMTAuOTNaIiBzdHlsZT0iZmlsbDojZmZmIi8+PC9zdmc+);
    background-size: 10px 10px;
}

.person-title {
    display: block;
    min-height: 2.6rem;
}

.section--body {
    .h1, .h2 {
        @screen xl {
            @apply inline-block max-w-2/3;
        }
    }
}

.section--card {
    picture {
        @apply block min-h-full;
        @screen lg {
            @apply flex justify-items-stretch items-stretch;
        }
        img {
            @apply min-h-full;
        }
    }
    .parallax {
        picture {
            @screen lg {
                @apply block;
            }
        }
    }
}