// .section--zPattern {
//     & > div {
//         @apply grid grid-cols-2 gap-x-four;
//     }
//     & > div:nth-child(2n) {
//         & > div {
//             @apply col-start-2;
//         }
//     }
// }

.card {
    .body {
        @apply col-span-6 flex justify-items-center items-center;
        & > div {
            @apply max-w-prose;
        }
    }
    .image {
        @apply col-span-4 relative overflow-hidden w-full;
        img {
            @apply inset-0 object-cover;
        }
        &.parallax {
            // img {
            //     @apply absolute top-auto bottom-0;
            // }
        }
    }
}

.row .card {
    @apply md:w-max;
}

body.nav-open {
    #nav-container {
        @apply overflow-y-scroll opacity-100 pointer-events-auto translate-y-0;
        @screen lg {
            @apply overflow-visible;
        }
    }
}

.blog-grid {
    @screen md {
        display: grid;
        grid-template-columns: 3fr 1fr;
        grid-template-rows: min-content min-content min-content;
    }
    grid-template-areas: 
        "body authors"
        "share authors"; 
    .metadata { grid-area: metadata; }
    .body { grid-area: body; }
    .share { grid-area: share; }
    .authors { grid-area: authors; }
}